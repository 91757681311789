export const showLoader = () => dispatch => {
    dispatch({
        type: "SHOW_LOADER"
    })
}

export const hideLoader = () => dispatch => {
    dispatch({
        type: "HIDE_LOADER"
    })
}

export const showHeader = () => dispatch => {
    dispatch({
        type: "SHOW_HEADER"
    })
}

export const hideHeader = () => dispatch => {
    dispatch({
        type: "HIDE_HEADER"
    })
}

export const showFormLoader = () => dispatch => {
    dispatch({
        type: "SHOW_FORM_LOADER"
    })
}

export const hideFormLoader = () => dispatch => {
    dispatch({
        type: "HIDE_FORM_LOADER"
    })
}