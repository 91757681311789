import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../actions/index'
import dynamic from 'next/dynamic'

const HocHead = dynamic(() => import('../HigherOrderComponent/HocHead'))
const FullPageLoader = dynamic(() => import('../FullPageLoader/FullPageLoader'))
const WorkSection = dynamic(() => import('./work/work-section'))
const HireDeveloperSection = dynamic(() => import('./hiredeveloper/hiredeveloper-section'), {
	ssr: false
})
const TestimonialSection = dynamic(() => import('./testimonial/testimonial-section'), {
	ssr: false
})
const ServiceSection = dynamic(() => import('./services/service-section'))
const ProcessSection = dynamic(() => import('./process/process-section'))
const StartupSection = dynamic(() => import('./startup/startup-section'), {
	ssr: false,
})
const FaqSection = dynamic(() => import('./faq/faq-section'))
const BannerSectionSSR = dynamic(() => import('./banner/banner-section'), {
	ssr: true,
})

function HomePage(props) {
	const blocks = props.blocks;
	const seo = props.seo;
	const loading = useSelector((state) => state.loaderReducer.loading)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(actions.hideLoader())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) //This will run only once

	return (
		<>
			{seo && <HocHead seo={seo} />}

			{loading ? <FullPageLoader /> : ''}

			<div className="homepage-middle">
				{blocks.length > 0 &&
					blocks.map((block, i) => {
						return (
							<React.Fragment key={i}>
								{block.__component === 'blocks.slider' && (
									<BannerSectionSSR slides={block.slide} />
								)}

								{block.__component === 'blocks.strategies' && (
									<ServiceSection strategies={block} />
								)}

								{block.__component === 'blocks.development-process' && (
									<ProcessSection process={block} />
								)}

								{block.__component === 'blocks.projects-new' && (
									<WorkSection projects={block} />
								)}

								{block.__component === 'blocks.scaleup-client' && (
									<StartupSection block={block} />
								)}

								{block.__component === 'blocks.hire-developer' && (
									<HireDeveloperSection block={block} />
								)}

								{block.__component === 'blocks.testimonials' && (
									<TestimonialSection block={block} />
								)}

								{block.__component === 'blocks.faq' && (
									<FaqSection faqs={block} />
								)}
							</React.Fragment>
						)
					})
				}
			</div>
		</>
	)
}

export default HomePage
